import React from 'react';
import get from 'lodash.get';
import { convertListOfValuesIntoOptionsForFormElement } from 'common/utils/form';
import { range } from 'common/utils/Array';
import taphuanSubTypes from 'configs/constants/org-sub-types.js';
import t1 from 'translate';
import Store from 'store';
import {
  isHieuTruongOrPho,
  isVGDTieuHoc,
  isVGDTrungHoc,
} from 'components/admin/user/utils';
import getUser from 'common/auth';
import Perm from 'common/utils/Perm';

export const qualificationOptions = () => [
  { value: 'tc', label: t1('qualification_tc') }, // Trung cấp
  { value: 'college', label: t1('qualification_college') }, // Cao đẳng
  { value: 'university', label: t1('qualification_university') }, // Đại học, Cử nhân hoặc tương đương
  { value: 'master', label: t1('qualification_master') }, // Thạc sĩ / MA
  { value: 'doctorate', label: t1('qualification_doctorate') }, // Tiến sĩ / Dr
  {
    value: 'assoc_prof_doctorate',
    label: t1('qualification_assoc_prof_doctorate'),
  }, // Phó giáo sư, Tiến sĩ / Assoc.Prof.Dr
  { value: 'prof_doctorate', label: t1('qualification_prof_doctorate') }, // Giáo sư, Tiến sĩ / Prof.Dr
];

export const userSchoolLevels = {
  NURSERY: 'nursery',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  HIGH: 'high',
};

export const userSchoolLevelToShortText = (key) => {
  const map = {
    [userSchoolLevels.NURSERY]: 'Mầm non',
    [userSchoolLevels.PRIMARY]: 'Tiểu học',
    [userSchoolLevels.SECONDARY]: 'THCS',
    [userSchoolLevels.HIGH]: 'THPT',
  };
  return map[key];
};

export const userSchoolLevelToText = (key) => {
  const map = {
    [userSchoolLevels.NURSERY]: 'Mầm non',
    [userSchoolLevels.PRIMARY]: 'Tiểu học',
    [userSchoolLevels.SECONDARY]: 'Trung học cơ sở',
    [userSchoolLevels.HIGH]: 'Trung học phổ thông',
  };
  return map[key];
};

export const userSchoolLevelToTextAndNumber = (key) => {
  const map = {
    [userSchoolLevels.NURSERY]: 'Mầm non',
    [userSchoolLevels.PRIMARY]: 'Tiểu học: 1-5',
    [userSchoolLevels.SECONDARY]: 'THCS: 6-9',
    [userSchoolLevels.HIGH]: 'THPT: 10-12',
  };
  return map[key];
};

export const userSchoolLevelOptions = () =>
  convertListOfValuesIntoOptionsForFormElement(
    Object.values(userSchoolLevels),
    (level) => (
      <span title={userSchoolLevelToText(level)}>
        {userSchoolLevelToShortText(level)}
      </span>
    ),
  );

export const userGrades = {
  NURSERY: 'nursery',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  HIGH: 'high',
};

// indicate that user has no specific grade
// dont want to add to above userGrades just because dont want to break other parts
export const USER_GRADE_NON = 'non';

export const userGradeToText = (key, isStatistic) => {
  const map = {
    [userGrades.NURSERY]: t1('user_grade_nursery'),
    [userGrades.PRIMARY]: t1('user_grade_primary'),
    [userGrades.SECONDARY]: t1('user_grade_secondary'),
    [userGrades.HIGH]: t1('user_grade_high'),
    ['unsorted']: t1('user_grade_unsorted'),
    ['all_school']: isStatistic
      ? t1('user_grade_all_school')
      : t1('user_grade_total'),
  };
  return map[key];
};

export const userGradeToShortText = (key) => {
  const map = {
    [userGrades.NURSERY]: t1('user_grade_short_text_nursery'), //'Mầm non',
    [userGrades.PRIMARY]: t1('user_grade_short_text_primary'), //'Tiểu học: 1-5',
    [userGrades.SECONDARY]: t1('user_grade_short_text_secondary'), //'THCS: 6-9',
    [userGrades.HIGH]: t1('user_grade_short_text_high'), //'THPT: 10-12',
    [USER_GRADE_NON]: t1('no_grade'),
  };
  return map[key];
};

const getUserGradeOptions = (getByUser) => {
  let options = convertListOfValuesIntoOptionsForFormElement(
    Object.values(userGrades),
    (grade) => (
      <span title={userGradeToText(grade)}>{userGradeToShortText(grade)}</span>
    ),
  );

  if (!getByUser) {
    return options;
  }

  const user = getUser();
  const userInfo = get(user, 'info');

  // TODO: Check with all users
  if (isVGDTieuHoc(userInfo)) {
    options = options.filter((grade) => {
      return grade.value === userGrades.PRIMARY;
    });
  } else if (isVGDTrungHoc(userInfo)) {
    options = options.filter((grade) => {
      return [userGrades.SECONDARY, userGrades.HIGH].includes(grade.value);
    });
  }

  return options;
};

export const userGradeOptions = (
  includeUnsorted,
  includeNoGrade,
  getByUser,
) => {
  let options = getUserGradeOptions(getByUser);

  if (options.length === 1) {
    return options;
  }

  if (includeUnsorted)
    options = [
      ...options,
      {
        value: 'unsorted',
        label: 'Chưa phân loại',
      },
    ];

  if (includeNoGrade) {
    options = [
      ...options,
      {
        value: USER_GRADE_NON,
        label: t1('no_grade'),
      },
    ];
  }

  return options;
};

const trainingGradeInUserGrade = (grade) => {
  return {
    [userGrades.PRIMARY]: range(1, 5),
    [userGrades.SECONDARY]: range(6, 9),
    [userGrades.HIGH]: range(10, 12),
  }[grade];
};

const userTrainingGradeToText = (number) => {
  return t1('khoi_hoc_%s', [number]);
};

export const userTrainingGradeOptions = (grade) =>
  convertListOfValuesIntoOptionsForFormElement(
    trainingGradeInUserGrade(grade),
    (number) => userTrainingGradeToText(number),
  );

export const leaderPositions = {
  LEADER: 'leader',
  VICE_LEADER: 'vice_leader',
  TEACHER: 'teacher',
  CHUYEN_VIEN_PHONG: 'chuyen_vien_phong',
  TRUONG_PHONG_GIAO_DUC: 'truong_phong_giao_duc',
  PHO_PHONG_GIAO_DUC: 'pho_phong_giao_duc',
  CHUYEN_VIEN_SO: 'chuyen_vien_so',
  TRUONG_PHONG_SO: 'truong_phong_so',
  PHO_GIAM_DOC_SO: 'pho_giam_doc_so',
  GIAM_DOC_SO: 'giam_doc_so',
  CHUYEN_VIEN_TRUONG: 'chuyen_vien_truong',
  TO_TRUONG: 'to_truong',

  CAN_BO_SO: 'staff_sso_so',
  CAN_BO_PHONG: 'staff_sso_phong',

  ADMIN_SSO_SO: 'admin_sso_so',
  ADMIN_SSO_TRUONG: 'admin_sso_truong',
  ADMIN_SSO_PHONG: 'admin_sso_phong',

  ADMIN_LTTU: 'admin_lttu',
  ADMIN_BGD: 'admin_bgd',
  ADMIN_RGEP: 'admin_rgep',
  ADMIN_HVQL: 'admin_hvql',
};

export const leaderPositionToText = (lp) => {
  return {
    [leaderPositions.TEACHER]: t1('leader_position_teacher'), //Giáo viên
    [leaderPositions.LEADER]: t1('leader_position_leader'), //Hiệu trưởng
    [leaderPositions.VICE_LEADER]: t1('leader_position_vice_leader'), //Phó hiệu trưởng
    [leaderPositions.CHUYEN_VIEN_PHONG]: t1(
      'leader_position_chuyen_vien_phong',
    ), //Chuyên viên phòng
    [leaderPositions.TRUONG_PHONG_GIAO_DUC]: t1(
      'leader_position_truong_phong_giao_duc',
    ), //Trưởng phòng giáo dục
    [leaderPositions.PHO_PHONG_GIAO_DUC]: t1(
      'leader_position_pho_phong_giao_duc',
    ), //Phó phòng giáo dục
    [leaderPositions.CHUYEN_VIEN_SO]: t1('leader_position_chuyen_vien_so'), //Chuyên viên Sở
    [leaderPositions.TRUONG_PHONG_SO]: t1('leader_position_truong_phong_so'), //Trưởng phòng Sở
    [leaderPositions.PHO_GIAM_DOC_SO]: t1('leader_position_pho_giam_doc_so'), //Phó giám đốc Sở
    [leaderPositions.GIAM_DOC_SO]: t1('leader_position_giam_doc_so'), //Giám đốc Sở
    [leaderPositions.CHUYEN_VIEN_TRUONG]: t1(
      'leader_position_chuyen_vien_truong',
    ), //Chuyên viên trường
    [leaderPositions.TO_TRUONG]: t1('leader_position_to_truong'), //Tổ trưởng
    [leaderPositions.ADMIN_SSO_SO]: t1('leader_position_admin_sso_so'), //Admin sở
    [leaderPositions.ADMIN_SSO_PHONG]: t1('leader_position_admin_sso_phong'), //Admin phòng
    [leaderPositions.ADMIN_SSO_TRUONG]: t1('leader_position_admin_sso_truong'), //Admin trường
    [leaderPositions.CAN_BO_SO]: t1('leader_position_can_bo_so'), //Cán bộ Sở
    [leaderPositions.CAN_BO_PHONG]: t1('leader_position_can_bo_phong'), //Cán bộ Phòng
  }[lp];
};

export const leaderPositionOptions = (user, orgSubType = null) => {
  let value = [];

  const currentLeaderPosition = get(user, 'leader_position');

  switch (Number.parseInt(orgSubType)) {
    case taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD: {
      value = [
        leaderPositions.GIAM_DOC_SO,
        leaderPositions.PHO_GIAM_DOC_SO,
        leaderPositions.TRUONG_PHONG_SO,
        leaderPositions.CHUYEN_VIEN_SO,
        leaderPositions.CAN_BO_SO,
        Perm.hasPerm('root') && leaderPositions.ADMIN_SSO_SO,
      ];
      break;
    }
    case taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD: {
      value = [
        leaderPositions.TRUONG_PHONG_GIAO_DUC,
        leaderPositions.PHO_PHONG_GIAO_DUC,
        leaderPositions.CHUYEN_VIEN_PHONG,
        leaderPositions.CAN_BO_PHONG,
        Perm.hasPerm('root') && leaderPositions.ADMIN_SSO_PHONG,
      ];
      break;
    }
    case taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_TIEUHOC:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_THCS:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_THPT:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_LIENCAP:
    case taphuanSubTypes.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP: {
      value = [
        leaderPositions.TEACHER,
        leaderPositions.LEADER,
        leaderPositions.VICE_LEADER,
        Perm.hasPerm('root') && leaderPositions.ADMIN_SSO_TRUONG,
        leaderPositions.CHUYEN_VIEN_TRUONG,
      ];
      break;
    }
    default: {
      value = [];
    }
  }

  if (
    !value.includes(currentLeaderPosition) &&
    [
      leaderPositions.ADMIN_SSO_SO,
      leaderPositions.ADMIN_SSO_PHONG,
      leaderPositions.ADMIN_SSO_TRUONG,
    ].includes(currentLeaderPosition)
  ) {
    value.push(currentLeaderPosition);
  }

  return convertListOfValuesIntoOptionsForFormElement(
    value.filter(Boolean),
    leaderPositionToText,
  );
};

export const subLeaderPositionOptions = (
  currentLeaderPosition = leaderPositions.LEADER,
) => {
  const value = [
    leaderPositions.LEADER,
    leaderPositions.VICE_LEADER,
    leaderPositions.TO_TRUONG,
  ].filter((val) => val !== currentLeaderPosition);

  return [
    {
      label: 'Hãy chọn chức vụ kiêm nhiệm',
      value: '',
    },
  ].concat(
    convertListOfValuesIntoOptionsForFormElement(value, leaderPositionToText),
  );
};

export const approveStatuses = {
  NOT_YET_APPROVED: 'not_yet_approved',
  APPROVED_BY_SGD: 'approved_by_sgd',
  APPROVED_BY_GVSP: 'approved_by_gvsp',
};

export const plannedTrainingStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const getDefaultPassword = () =>
  get(Store.getState(), 'domainInfo.conf.default_password_when_import_user') ||
  'Abc@1234';

export const TARGET_USER = {
  GVPT: 'gv',
  CBQL: 'cbql',
};

export const targetUserOptions = () => [
  {
    value: TARGET_USER.GVPT,
    label: 'GV',
  },
  {
    value: TARGET_USER.CBQL,
    label: 'CBQL',
  },
];

export const getTargetUser = (key) => {
  const map = {
    [TARGET_USER.GVPT]: 'GV',
    [TARGET_USER.CBQL]: 'CBQL',
  };

  return map[key] || '';
};

export const targetUserOptionsByLeaderPosition = (user) => {
  const options = [
    {
      value: TARGET_USER.GVPT,
      label: 'GV',
    },
  ];

  if (isHieuTruongOrPho(user)) {
    options.push({
      value: TARGET_USER.CBQL,
      label: 'CBQL',
    });
  }

  return options;
};

export const reviewStatusOptions = () => [
  {
    value: 'queued',
    label: t1('queued'),
  },
  {
    value: 'approved',
    label: t1('approved'),
  },
  {
    value: 'reject',
    label: t1('reject'),
  },
];

export const validOptions = () => [
  {
    value: 'valid',
    label: t1('valid'),
  },
  {
    value: 'expired',
    label: t1('expired'),
  },
  {
    value: 'invalid',
    label: t1('invalid'),
  },
];

export const workingOptions = () => [
  {
    value: 'working',
    label: t1('working_options_working_label'),
  },
  {
    value: 'retirement',
    label: t1('working_options_retirement_label'),
  },
  {
    value: 'quit_work',
    label: t1('working_options_quit_work_label'),
  },
  {
    value: 'work_break',
    label: t1('working_options_work_break_label'),
  },
  {
    value: 'job_transfer',
    label: t1('working_options_job_transfer_label'),
  },
];

export const teachingSpecializationOptions = () => [
  {
    value: 'giao_duc_hoc',
    primaryText: t1('teaching_specialization_giao_duc_hoc'), //'Giáo dục học',
    label: t1('teaching_specialization_giao_duc_hoc'), //'Giáo dục học',
  },
  {
    value: 'quan_ly_giao_duc',
    primaryText: t1('teaching_specialization_quan_ly_giao_duc'), //'Quản lý giáo dục',
    label: t1('teaching_specialization_quan_ly_giao_duc'), //'Quản lý giáo dục',
  },
  {
    value: 'other',
    primaryText: t1('teaching_specialization_other'), //'Khác (ghi rõ)',
    label: t1('teaching_specialization_other'), //'Khác (ghi rõ)',
  },
];
